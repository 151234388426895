import React, { useState, useEffect } from "react";
import { Router, Redirect } from "@reach/router";
import { fromEvent } from "rxjs";
import { useCss, k } from "kremling";
import { ErrorBoundary } from "error-logging!sofe";
import auth, { useWithUserAndTenant, useHasAccess } from "cp-client-auth!sofe";

import Navigation from "./navigation/navigation.component";
import Home from "./home/home.component";
import { FilesRouter } from "./files/files-router.component";
import Todo from "./todo/todo-router.component";
import Billing from "./billing/billing-router.component";
import Settings from "./settings/settings.component";
import PaymentSettings from "./payment-settings/payment-settings.component";
import SurchargeDisclosure from "./billing/surcharge-disclosure-modal.component";
import { PageNotFound } from "./page-not-found.component";
import { ClientNotFound } from "./not-found-pages/client-not-found.component";
import { getBasePath } from "@utils/routing";
import { useSetVariantBrandingColors } from "./white-label/custom-hooks";
import { useCanopyPayments, useBalance } from "./billing/billing-hooks";
import { ClientContext, useClients } from "./client-portal.context";
import { BillingContext } from "./billing/billing-context";
import "./global-styles.scss";
import { featureEnabled } from "feature-toggles!sofe";

export const Root = ErrorBoundary({ featureName: "mobile-client-ui" })(() => {
  const scope = useCss(css);
  const [user, tenant] = useWithUserAndTenant();
  const ft_crm =
    featureEnabled("ft_crm") && tenant.crm_status === "crm_hierarchy_complete";

  const whiteLabelSettings = useSetVariantBrandingColors();
  const {
    hasCanopyPayments,
    amexEnabled,
    hasAdyen,
    surchargeEnabled,
    surchargeViewed,
    surchargeFeeText,
  } = useCanopyPayments();
  const hasBillingPermissions = useHasAccess("client_billing");

  // keep track of user sessions and log them out when their sessions expire
  useEffect(() => {
    auth.trackSession();
    const si = document.querySelector(
      "#sofe-inspector > div > div:nth-child(2)"
    );
    if (si) {
      si.style.top = "0";
      si.style.left = "0";
    }
  }, []);

  const [clients, selectedClient, selectClient, entities] = useClients(ft_crm);
  const {
    results: balance,
    loading: loadingBalance,
    resubscribe: refetchBalance,
  } = useBalance(
    selectedClient?.id,
    whiteLabelSettings?.show_cp_billing,
    hasBillingPermissions
  );
  const [showNav, setShowNav] = useState(true);
  const [enableBilling, setEnableBilling] = useState(true);

  const [pageHeaderOverride, overridePageHeader] = useState();
  const [showPaymentFlow, setShowPaymentFlow] = useState(false);
  const [showSurchargeDisclosure, setShowSurchargeDisclosure] = useState(false);

  useEffect(() => {
    let subscription = fromEvent(
      window,
      "billing-ui::payment-complete"
    ).subscribe(() => {
      refetchBalance();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (whiteLabelSettings) {
      setEnableBilling(
        whiteLabelSettings.show_cp_billing && hasBillingPermissions
      );
    }
  }, [whiteLabelSettings, hasBillingPermissions]);

  useEffect(() => {
    setShowSurchargeDisclosure(surchargeEnabled && !surchargeViewed);
  }, [surchargeViewed]);

  // DO NOT REMOVE 'fs-exclude' it has been added to
  // prevent fullstory picking up client portal sessions
  return (
    <ClientContext.Provider
      value={{
        clients,
        selectedClient,
        selectClient,
        showNav,
        setShowNav,
        user,
        tenant,
        entities,
        ft_crm,
      }}
    >
      {user && tenant && (
        <div {...scope} className="client ios-scrolling fs-exclude">
          <BillingContext.Provider
            value={{
              pageHeaderOverride,
              overridePageHeader,
              hasCanopyPayments,
              amexEnabled,
              hasAdyen,
              surchargeEnabled,
              surchargeFeeText,
              showPaymentFlow,
              setShowPaymentFlow,
              setShowSurchargeDisclosure,
              balance: balance?.unpaid_balance?.total,
              credit: balance?.available_credit,
              refetchBalance,
              loadingBalance,
            }}
          >
            {showNav && clients?.length > 0 && (
              <Router basepath={getBasePath()}>
                <Navigation
                  selectedClient={selectedClient}
                  tenant={tenant}
                  path="/*"
                  enableBilling={enableBilling}
                />
              </Router>
            )}
            {selectedClient === null && <ClientNotFound />}
            {showSurchargeDisclosure && enableBilling && (
              <SurchargeDisclosure
                show={showSurchargeDisclosure}
                onClose={() => setShowSurchargeDisclosure(false)}
                surchargeViewed={surchargeViewed}
                surchargeFeeText={surchargeFeeText}
              />
            )}
            {selectedClient && (
              <Router
                className={`${showNav ? "content" : ""}`}
                basepath={getBasePath()}
                primary={false}
              >
                <PageNotFound default />
                <Home path="/" enableBilling={enableBilling} />
                <Todo path="/todo/*" selectedClient={selectedClient} />
                <FilesRouter path="/files/*" />
                <Redirect from="/billing" to="invoices" noThrow />
                {enableBilling && (
                  <Billing path="/billing/*" selectedClient={selectedClient} />
                )}
                <Settings path="/settings" user={user} />
                {enableBilling && <PaymentSettings path="/payment-settings" />}
              </Router>
            )}
          </BillingContext.Provider>
        </div>
      )}
    </ClientContext.Provider>
  );
});

const css = k`
  .cps-flexible-focus {
    min-width: 10rem;
    max-width: 95%;
  }

  .focusable-element:focus {
    box-shadow: var(--cp-form-focus-state);
    outline: none;
    transition: box-shadow var(--cp-form-transition-duration) ease, border var(--cp-form-transition-duration) ease;
  }

  .focusable-element-inset:focus {
    box-shadow: var(--cp-form-focus-state-inset);
    outline: none;
    transition: box-shadow var(--cp-form-transition-duration) ease, border var(--cp-form-transition-duration) ease;
  }

  .flex {
    display: flex;
  }

  .flex-v-center {
    display: flex;
    align-items: center;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .ios-scrolling {
    -webkit-overflow-scrolling: touch;
  }

  @media #{$--phone-only} {
    .page-content {
      padding-bottom: 96px;
      margin-left: auto;
      margin-right: auto;
      min-width: 260px;
    }

    .page-content.no-footer {
      padding-bottom: 0;
    }

    .page-content.tall-footer{
      padding-bottom: 126px;
    }

    .content {
      padding-top: 69px;
      max-height: calc(100vh - 69px);
    }
  }

  @media #{$--tablet-portrait-up} {
    .content {
      margin-left: 290px;
      width: calc(100% - 290px);
    }

    .page-content {
      padding: 0 4rem;
      margin-left: auto;
      margin-right: auto;
      min-width: 46rem;
      max-width: 79.6rem;
    }
  }
`;
