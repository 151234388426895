import React, { useEffect, useState } from "react";
import { fetchClients, fetchEntities } from "./client-portal.resource";
import { handleError } from "src/handle-error";
import { globalHistory, navigate } from "@reach/router";
import { useRefetchTodos } from "@hooks/use-refetch-todos.hook";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

export const ClientContext = React.createContext({
  clients: undefined,
  selectedClient: undefined,
  entities: [],
});

export const useClients = function useClients(ft_crm) {
  const [clients, setClients] = useState();
  const [client, selectClient] = useState();
  const [clientId, setClientId] = useState();
  const [entities, setEntities] = useState([]);
  const refetchTodos = useRefetchTodos();
  const params = window.location.search;
  const [user] = useWithUserAndTenant();

  useEffect(() => {
    if (user) {
      const subscription = fetchEntities().subscribe(
        (res) => setEntities(res),
        handleError
      );
      return () => subscription?.unsubscribe();
    }
  }, [user]);

  const checkUrlChange = (pathname) => {
    // Correct any links that had the old path in it.
    if (pathname.includes("/m/contacts/")) {
      navigate(pathname.replace("/m/contacts/", "/m/clients/") + params);
    }
    if (pathname === "/m/") {
      navigate("/m/clients/" + params);
    }
    // First time running, set the clientId if it's not in the url,
    const match = pathname.match(/m\/clients\/([0-9A-Za-z]+)/);
    if (match?.length) {
      const clientIdFromURL = match[1];
      if (clientId !== clientIdFromURL) {
        localStorage.setItem("cp:client-id", clientIdFromURL);
        setClientId(clientIdFromURL);
      }
    }
  };

  useEffect(() => {
    checkUrlChange(window.location.pathname);
    const disposable = fetchClients({ ft_crm }).subscribe(
      (res) => setClients(res),
      handleError
    );
    const ghDisposable = globalHistory.listen((history) =>
      checkUrlChange(history.location.pathname)
    );
    return () => {
      disposable.unsubscribe();
      ghDisposable();
    };
  }, []);

  useEffect(() => {
    if (!clientId && clients?.length) {
      addClientIdToURL(window.location.pathname);
    }

    // Redirects from the old client portal will pass the old link chunked into query params.
    // This can be removed once we are satisfied old notification links are no longer being used.
    if (clientId && params) {
      staleNotificationRedirect(clientId);
    }
  }, [clientId, clients]);

  const staleNotificationRedirect = (clientId) => {
    const searchParams = new URLSearchParams(params);
    searchParams.forEach((val, key) => {
      if ((key === "billing" && val === "true") || key === "payment") {
        navigate(`/m/clients/${clientId}/billing`);
      }

      if (key === "files" && val === "true") {
        navigate(`/m/clients/${clientId}/files`);
      }

      if (
        ["resolution_case", "survey", "request", "esign", "surveys"].some(
          (type) => key === type
        )
      ) {
        navigate(`/m/clients/${clientId}/todo`);
      }
    });
  };

  const addClientIdToURL = (pathname) => {
    const lsId = localStorage.getItem("cp:client-id");
    const id =
      lsId && clients.some((c) => c.id === lsId) ? lsId : clients[0].id;
    navigate(pathname.replace("/m/clients", `/m/clients/${id}`) + params);
  };

  useEffect(() => {
    if (clients) {
      const matchingClientIndex = clients.findIndex((c) => c.id == clientId);
      if (clientId) {
        if (matchingClientIndex !== -1)
          selectClient(clients[matchingClientIndex]);
        else selectClient(null);
      } else if (clients[0] !== undefined) {
        selectClient(clients[0]);
      } else {
        selectClient(null);
      }
    }
  }, [clients, clientId]);

  useEffect(() => client && refetchTodos(client), [client]);

  return [clients, client, selectClient, entities];
};
