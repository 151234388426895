import React from "react";

export const BillingContext = React.createContext({
  pageHeaderOverride: undefined,
  overridePageHeader: () => {},
});

export const PaymentContext = React.createContext({
  selectedInvoices: [],
});
