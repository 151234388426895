import React from "react";
import { DateTime } from "luxon";
import { getRequestStatus } from "./todo.helper";

export function getFormattedDueDate({ dueDate, status }) {
  const completed = getRequestStatus(status) === "complete";
  const formattedDueDate = dueDate
    ? typeof dueDate === "number"
      ? DateTime.fromMillis(dueDate).toFormat("LLL dd, yyyy")
      : DateTime.fromISO(dueDate).toFormat("LLL dd, yyyy")
    : "";
  let finalDueDateFormat = "";
  if (formattedDueDate) {
    finalDueDateFormat = `${
      completed ? "Completed" : "Due"
    } ${formattedDueDate}`;
  }
  return finalDueDateFormat;
}

export function getFormattedCompleteDate(completedDate) {
  return completedDate
    ? `Completed ${DateTime.fromISO(completedDate).toFormat("LLL dd, yyyy")}`
    : "";
}

export function getIconName({
  complete,
  todoType,
  pivotType,
  toolType,
  surveyType,
}) {
  let iconName = "checkmark-circle-open-large";
  if (!complete) {
    switch (todoType) {
      case "survey":
        iconName =
          surveyType === "taxRes"
            ? "communication-clipboard-check"
            : "communication-paper-pen";
        break;

      case "client_request":
      case "engagement":
      case "task_client_request": {
        iconName =
          pivotType === "esign_doc" || toolType === "esign_doc"
            ? "communication-signature-pen"
            : "communication-clipboard";
        break;
      }

      default:
        break;
    }
  }

  return iconName;
}

export function getLink({ id, type, resolutionCaseId, pivotType, toolType }) {
  const prefix = location.pathname.includes("todo") ? "" : "todo/";
  switch (type) {
    case "survey":
      return resolutionCaseId
        ? `./${prefix}survey/${id}/resolution-case/${resolutionCaseId}/welcome`
        : `./${prefix}survey/${id}`;
    case "client_request":
    case "task_client_request":
      return pivotType === "esign_doc" || toolType === "esign_doc"
        ? `./${prefix}esign/${id}${
            resolutionCaseId ? `/resolution-case/${resolutionCaseId}` : ""
          }`
        : `./${prefix}request/${id}${
            resolutionCaseId ? `/resolution-case/${resolutionCaseId}` : ""
          }`;
    case "engagement":
      return `./${prefix}engagement/${id}`;
    default:
      return `./error`;
  }
}

export function getBadgeTextAndType({ dueDate, viewedAt, status }) {
  let badgeText, badgeType;
  const luxonDueDate = dueDate
    ? typeof dueDate === "number"
      ? DateTime.fromMillis(dueDate)
      : DateTime.fromISO(dueDate)
    : null;
  const daysDifferenceToToday = luxonDueDate?.diff(
    DateTime.local(),
    "days"
  ).days;

  if (
    status === "needs_review" ||
    status === "REVIEW" ||
    status === "NEEDS_REVIEW"
  ) {
    // If it has been marked as complete by customer or needs review by practitioner it shows Needs review (status)
    badgeText = "In Review";
    badgeType = "REVIEW";
  } else if (daysDifferenceToToday <= -1) {
    // For the difference that we check, if the date is today it'll be between 0 and -1 and we don't want to show overdue for those
    // If it's past the due date it always shows Overdue
    badgeText = "Overdue";
    badgeType = "OVERDUE";
  } else if (!viewedAt) {
    // Never been viewed
    badgeText = "New";
    badgeType = "NEW";
  } else {
    badgeText = "";
    badgeType = "UNMARKED";
  }

  return {
    badgeText,
    badgeType,
  };
}

export function taskIsComplete(status) {
  return status === "complete" || status === "DONE";
}

export function renderDate({ completedDate, pendingCompletion, dueDate }) {
  if (completedDate) {
    return <div>Completed {completedDate}</div>;
  } else if (pendingCompletion) {
    return <div>Pending</div>;
  }

  if (dueDate) {
    return <div>Due {dueDate}</div>;
  }
  return;
}
