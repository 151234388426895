import React from "react";
import { get } from "lodash";
import { Link } from "@reach/router";
import { useCss, a } from "kremling";
import css from "./todo-card.krem.css";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";
import {
  getLink,
  getFormattedDueDate,
  getIconName,
  getFormattedCompleteDate,
} from "./todo-card.helper";
import { getRequestStatus } from "./todo.helper";
import { StatusBadge } from "./status-badge.component";

export default function TodoCard({ task, cameFrom }) {
  const {
    due_at: dueAt,
    status,
    type,
    id,
    title,
    unread_comments: unreadComments,
    pivot_type: pivotType,
    tool_type: toolType,
    client_completed_at: clientCompletedAt,
    practitioner_completed_at: practitionerCompletedAt,
    surveyType,
    surveyData,
  } = task;
  const scope = useCss(css);
  const dueDateText = getFormattedDueDate({ dueDate: dueAt, status });
  const completedDateText = getFormattedCompleteDate(
    clientCompletedAt || practitionerCompletedAt
  );
  const isComplete = getRequestStatus(status) === "complete";
  const iconName = getIconName({
    complete: isComplete,
    todoType: type,
    pivotType,
    toolType,
    surveyType,
  });
  const resolutionCaseId = getResolutionCaseId(task);
  const link = getLink({ id, type, resolutionCaseId, pivotType, toolType });
  const isSurveyOrOrganizer = !!surveyType;
  const todoTitle =
    task.surveyType === "taxPrep"
      ? `${surveyData?.returnYear} ${title} ${
          surveyData?.returnType && `(${surveyData?.returnType})`
        }`
      : title;

  return (
    <Link
      to={link}
      state={{ cameFrom }}
      {...scope}
      className={a("task-card cps-card__height-2").m(
        "task-card-completed",
        isComplete
      )}
    >
      <div className="cp-mr-16">
        <CpIcon name={iconName} />
      </div>
      <div className="task-card-body">
        <CpTooltip text={title}>
          <div
            className={a("cps-body cps-wt-semibold name").m(
              "completed-strikethrough",
              isComplete
            )}
          >
            {todoTitle}
          </div>
        </CpTooltip>
        <div className="date-and-new-msg">
          {(completedDateText || dueDateText || isSurveyOrOrganizer) && (
            <div className="cps-caption date-text">
              {completedDateText || dueDateText || "Due as soon as possible"}
            </div>
          )}
          {!!unreadComments && (
            <CpIcon name="communication-chat-bubble" className="new-msg" />
          )}
        </div>
      </div>
      <StatusBadge task={task} />
    </Link>
  );
}

function getResolutionCaseId(rawData) {
  const relationshipType = get(rawData, "relationships.for.type");
  if (rawData.resolutionCaseId) {
    return rawData.resolutionCaseId;
  } else if (rawData.engagementId) {
    return rawData.engagementId;
  } else if (relationshipType === "resolution_case") {
    return get(rawData, "relationships.for.id");
  } else {
    return undefined;
  }
}
